import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import Cookies from 'universal-cookie';

let cookies = new Cookies()

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./managerviews/managerlogin/ManagerLogin'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

class App extends Component {

  //로컬스토리지에 adminID 없거나 or 쿠키에 access_token이 없으면 로그인화면 (관리자 로그인)
  //로컬스토리지에 managerID or siteID가 없거나 or 쿠키에 access_token이 없으면 로그인화면 (매니저 로그인)
  //그 외에 상황은 홈화면
  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              {((!localStorage.getItem('adminID') || !cookies.get("access_token")) && ((!localStorage.getItem('managerID') || !localStorage.getItem('siteID')) || !cookies.get("access_token")))
              ? <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              : <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />}
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
